import { graphql, useStaticQuery } from 'gatsby';
import { FormattedDate, Link, useIntl } from 'gatsby-plugin-intl';
import React from 'react';

import { FORMATTED_DATE_OPTIONS, ROUTES, getLocalizedDataFromContentfulEdges } from '../../../utils';
import styles from './ourMission.module.scss';

export const OurMission = () => {
	const intl = useIntl();
	const data = useStaticQuery(graphql`
		{
			allContentfulOurMission {
				edges {
					node {
						node_locale
						ourMissionBlocks {
							id
							title
							description
						}
						mealKitBlocks {
							id
							title
							description
						}
						latest {
							latestPublication {
								title
								url
								date
							}
							latestNews {
								title
								pdf {
									file {
										url
									}
								}
								date
							}
							latestStory {
								createdAt
								id
								title
							}
						}
						sustainabilityLearnMoreLink
						slogan
						title
						mealKitTitle
					}
				}
			}
		}
	`);
	const {
		node: { mealKitBlocks, mealKitTitle, ourMissionBlocks, slogan, title, latest },
	} = getLocalizedDataFromContentfulEdges(data.allContentfulOurMission.edges, intl.locale)[0];

	return (
		<div className={styles.ourMissionContainer}>
			<Mission slogan={slogan} title={title} ourMissionBlocks={ourMissionBlocks} />
			<Latest data={latest} intl={intl} />
			<MealKit mealKitBlocks={mealKitBlocks} mealKitTitle={mealKitTitle} />
		</div>
	);
};

const Mission = ({ title, slogan, ourMissionBlocks }) => {
	// const intl = useIntl();
	const renderBlocks = () => {
		return ourMissionBlocks.map(mission => (
			<div key={mission.id} className={styles.block}>
				<h3>{mission.title}</h3>
				<div>{mission.description}</div>
				{/*{i === ourMissionBlocks.length - 1 && <a href={learnMoreLink}>{intl.formatMessage({ id: 'learn_more' })}</a>}*/}
			</div>
		));
	};
	return (
		<>
			<section className={styles.mission}>
				<div className={styles.ourMission}>{slogan}</div>
				<h2>{title}</h2>
			</section>
			<section className={styles.missionList}>{renderBlocks()}</section>
		</>
	);
};

const Latest = ({ data, intl }) => {
	const story = data.latestStory;
	const news = data.latestNews;
	const publication = data.latestPublication;
	const { locale } = intl;
	const getFormattedDate = date => <FormattedDate value={new Date(date)} {...FORMATTED_DATE_OPTIONS} locale={locale} />;
	return (
		<section className={styles.latest}>
			<Link to={`${ROUTES.newsroom.stories}/${story.id}`}>
				<div className={styles.card}>
					<div className={styles.cardHeader}>
						<h3>{intl.formatMessage({ id: 'latest_story' })}</h3>
						<div className={styles.cardDate}>{getFormattedDate(story.createdAt)}</div>
					</div>
					<div className={styles.cardContent}>{story.title}</div>
				</div>
			</Link>
			<a href={`https:${news.pdf.file.url}`} target="_blank" rel="noreferrer">
				<div className={styles.card}>
					<div className={styles.cardHeader}>
						<h3>{intl.formatMessage({ id: 'latest_news' })}</h3>
						<div className={styles.cardDate}>{getFormattedDate(news.date)}</div>
					</div>
					<div className={styles.cardContent}>{news.title}</div>
				</div>
			</a>
			<a href={publication.url} target="_blank" rel="noreferrer">
				<div className={styles.card}>
					<div className={styles.cardHeader}>
						<h3>{intl.formatMessage({ id: 'latest_publications' })}</h3>
						<div className={styles.cardDate}>{getFormattedDate(publication.date)}</div>
					</div>
					<div className={styles.cardContent}>{publication.title}</div>
				</div>
			</a>
		</section>
	);
};

const MealKit = ({ mealKitTitle, mealKitBlocks }) => {
	const renderBlocks = () => {
		return mealKitBlocks.map(kit => (
			<div key={kit.id} className={styles.numberData}>
				<h1 className={styles.number}>{kit.title}</h1>
				<p>{kit.description}</p>
			</div>
		));
	};
	return (
		<section className={styles.mealKit}>
			<h2>{mealKitTitle}</h2>
			{renderBlocks()}
		</section>
	);
};
