import { graphql, useStaticQuery } from 'gatsby';
import { FormattedDate, Link, useIntl } from 'gatsby-plugin-intl';
import React from 'react';

import {
	FORMATTED_DATE_OPTIONS,
	QUICK_NAVIGATION_STORIES,
	ROUTES,
	getLocalizedDataFromContentfulEdges,
} from '../../../utils';
import { ContentLayout } from '../../ContentLayout';
import { QuickBottomNavigationWrapper } from '../..';
import styles from './DesktopStories.module.scss';

export const DesktopStories = () => {
	const data = useStaticQuery(graphql`
		{
			allContentfulStory(sort: { order: DESC, fields: createdAt }) {
				edges {
					node {
						node_locale
						photo {
							fluid(quality: 100) {
								src
							}
						}
						title
						description
						createdAt
						id
						contentful_id
						slug
					}
				}
			}
		}
	`);
	const intl = useIntl();
	const { locale } = intl;
	const stories = getLocalizedDataFromContentfulEdges(data.allContentfulStory.edges, locale);
	const renderBlocks = ({ node }) => {
		const { id, contentful_id, photo, createdAt, title, description, slug } = node;
		const photoSrc = photo && photo.fluid && photo.fluid.src;
		return (
			<Link key={id} to={`${ROUTES.newsroom.stories}/${slug || contentful_id}`} className={styles.container}>
				<div className={styles.block}>
					{photoSrc && <div className={styles.background} style={{ backgroundImage: `url(${photoSrc})` }} />}
					<div className={styles.card}>
						<p>
							<FormattedDate value={new Date(createdAt)} {...FORMATTED_DATE_OPTIONS} locale={locale} />
						</p>
						<h3>{title}</h3>
						<p>{description}</p>
					</div>
				</div>
			</Link>
		);
	};
	return (
		<QuickBottomNavigationWrapper optionalDataArray={QUICK_NAVIGATION_STORIES}>
			<ContentLayout>
				<div className={styles.desktopContainer}>{stories && stories.map(renderBlocks)}</div>
			</ContentLayout>
		</QuickBottomNavigationWrapper>
	);
};
