import { useLocation } from '@reach/router';
import cx from 'classnames';
import { Link, changeLocale, useIntl } from 'gatsby-plugin-intl';
import React, { useEffect, useState } from 'react';

import Arrow from '../../assets/icons/expand-more.svg';
import Menu from '../../assets/icons/icon-hamburger.svg';
import Logo from '../../assets/logo/hellofresh-group.svg';
import {
	CURRENT_LOCALES,
	ROUTES,
	TECHBLOG_URL,
	convertLocalesToEQSUrlValues,
	findNavigationObjectFromUrl,
} from '../../utils';
import { useLockBodyScroll } from '../../utils/hooks';
import { ContentLayout } from '../ContentLayout';
import styles from './MobileHeader.module.scss';

export const MobileHeader = () => {
	const intl = useIntl();
	const location = useLocation();
	const [isMobileOpen, setIsMobileOpen] = useState(false);
	const [isNewsroomSubNavOpen, setIsNewsroomSubNavOpen] = useState(false);
	const [isSustainabilitySubNavOpen, setIsSustainabilitySubNavOpen] = useState(false);
	const [isRelationsSubNavOpen, setIsRelationsSubNavOpen] = useState(false);

	useEffect(() => {
		if (isMobileOpen) {
			setIsNewsroomSubNavOpen(false);
			setIsRelationsSubNavOpen(false);
			setIsSustainabilitySubNavOpen(false);
		}
	}, [isMobileOpen]);

	useLockBodyScroll(isMobileOpen);

	const renderNewsroomSubNavigation = () => {
		return [
			{ path: ROUTES.newsroom.pressReleases, translation: intl.formatMessage({ id: 'nav.press_releases' }) },
			{ path: ROUTES.newsroom.stories, translation: intl.formatMessage({ id: 'nav.stories' }) },
			{ href: TECHBLOG_URL, translation: intl.formatMessage({ id: 'nav.tech_blog' }) },
			{ path: ROUTES.newsroom.mediaGallery, translation: intl.formatMessage({ id: 'nav.downloads' }) },
			{ path: ROUTES.newsroom.contact, translation: intl.formatMessage({ id: 'nav.contact' }) },
		].map(({ path, href, translation }) => (
			<li onClick={() => setIsMobileOpen(false)} key={path || href}>
				{href ? <a href={href}>{translation}</a> : <Link to={path}>{translation}</Link>}
			</li>
		));
	};

	const renderSustainabilitySubNavigation = () => {
		return [
			{ anchor: ROUTES.sustainability.ourPlanet, translation: intl.formatMessage({ id: 'nav.our_planet' }) },
			{ anchor: ROUTES.sustainability.ourPeople, translation: intl.formatMessage({ id: 'nav.our_people' }) },
			{ anchor: ROUTES.sustainability.ourCommunity, translation: intl.formatMessage({ id: 'nav.our_community' }) },
			{ anchor: ROUTES.sustainability.ourStandards, translation: intl.formatMessage({ id: 'nav.our_standards' }) },
			{
				anchor: ROUTES.sustainability.ourWhistleblowerSystem,
				translation: intl.formatMessage({ id: 'nav.our_whistleblower_system' }),
			},
		].map(({ anchor, translation }) => (
			<li onClick={() => setIsMobileOpen(false)} key={anchor}>
				<Link to={ROUTES.sustainability.index} state={{ anchorData: anchor }}>
					{translation}
				</Link>
			</li>
		));
	};

	const renderInverstorRelationsSubNavigation = () => {
		return [
			{ href: ROUTES.investorRelations.shares(eqsLocale), translation: intl.formatMessage({ id: 'nav.shares' }) },
			{
				href: ROUTES.investorRelations.publications(eqsLocale),
				translation: intl.formatMessage({ id: 'nav.publications' }),
			},
			{
				href: ROUTES.investorRelations.annualGeneralMeeting(eqsLocale),
				translation: intl.formatMessage({ id: 'nav.annual_general_meeting' }),
			},
			{ href: ROUTES.investorRelations.news(eqsLocale), translation: intl.formatMessage({ id: 'nav.news' }) },
			{
				href: ROUTES.investorRelations.governance(eqsLocale),
				translation: intl.formatMessage({ id: 'nav.governance' }),
			},
			{
				href: ROUTES.investorRelations.financialCalendar(eqsLocale),
				translation: intl.formatMessage({ id: 'nav.financial_calendar' }),
			},
			{
				href: ROUTES.investorRelations.convertibleBonds(eqsLocale),
				translation: intl.formatMessage({ id: 'nav.convertible_bonds' }),
			},
			{ href: ROUTES.investorRelations.irServices, translation: intl.formatMessage({ id: 'nav.ir_services' }) },
		].map(({ href, translation }) => (
			<li onClick={() => setIsMobileOpen(false)} key={href}>
				<a href={href}>{translation}</a>
			</li>
		));
	};

	const eqsLocale = convertLocalesToEQSUrlValues(intl.locale);
	const navObj = findNavigationObjectFromUrl(location.pathname);

	return (
		<section className={styles.container}>
			<ContentLayout className={styles.wrapper}>
				<div className={styles.leftSideMenuWrapper}>
					<Link to={ROUTES.aboutUs} className={styles.logoLink}>
						<Logo className={styles.logo} alt="logo" />
					</Link>
					{navObj !== undefined && (
						<div className={styles.route}>{intl.formatMessage({ id: `nav.${navObj.translationKey}` })}</div>
					)}
				</div>
				<div className={styles.menuIcon} onClick={() => setIsMobileOpen(!isMobileOpen)}>
					<Menu />
				</div>
				<div className={cx(styles.mobileNav, isMobileOpen && styles.show)}>
					<ul className={styles.mobileMenu}>
						<li>
							<Link to={ROUTES.aboutUs}>{intl.formatMessage({ id: 'nav.about_us' })}</Link>
						</li>

						<li onClick={() => setIsNewsroomSubNavOpen(!isNewsroomSubNavOpen)}>
							{intl.formatMessage({ id: 'nav.newsroom' })} <Arrow />
							{isNewsroomSubNavOpen && <ul className={styles.subNav}>{renderNewsroomSubNavigation()}</ul>}
						</li>
						<li onClick={() => setIsSustainabilitySubNavOpen(!isSustainabilitySubNavOpen)}>
							{intl.formatMessage({ id: 'nav.sustainability' })} <Arrow />
							{isSustainabilitySubNavOpen && <ul className={styles.subNav}>{renderSustainabilitySubNavigation()}</ul>}
						</li>
						<li onClick={() => setIsRelationsSubNavOpen(!isRelationsSubNavOpen)}>
							{intl.formatMessage({ id: 'nav.investor_relations' })} <Arrow />
							{isRelationsSubNavOpen && <ul className={styles.subNav}>{renderInverstorRelationsSubNavigation()}</ul>}
						</li>
					</ul>
					<section className={styles.language}>
						<div>
							<span
								className={cx(intl.locale === CURRENT_LOCALES.de && styles.languageActive)}
								onClick={() => changeLocale(CURRENT_LOCALES.de)}>
								{CURRENT_LOCALES.de}
							</span>
							|
							<span
								className={cx(intl.locale === CURRENT_LOCALES.en && styles.languageActive)}
								onClick={() => changeLocale(CURRENT_LOCALES.en)}>
								{CURRENT_LOCALES.en}
							</span>
						</div>
					</section>
				</div>
			</ContentLayout>
		</section>
	);
};
