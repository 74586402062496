import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import { useIntl } from 'gatsby-plugin-intl';
import React from 'react';

import { getLocalizedDataFromContentfulEdges } from '../../../utils';
import styles from './supervisory.module.scss';

export const Supervisory = () => {
	const intl = useIntl();
	const data = useStaticQuery(graphql`
		{
			allContentfulSupervisoryBoard {
				edges {
					node {
						node_locale
						title
						linkTitle
						person {
							name
							position
							photo {
								fluid {
									...GatsbyContentfulFluid
								}
							}
						}
					}
				}
			}
		}
	`);
	const {
		node: { title: supervisoryTitle, person: supervisoryPerson, linkTitle },
	} = getLocalizedDataFromContentfulEdges(data.allContentfulSupervisoryBoard.edges, intl.locale)[0];

	const renderPerson = (person, index) => {
		const { name, position, photo = null } = person;
		return (
			<div key={`about-supervisory-${index}`} className={styles.supervisoryPerson}>
				<div className={styles.supervisoryPersonImageWrapper}>
					{photo && photo.fluid && <Img fluid={photo.fluid} alt={name} />}
				</div>
				<h3>{name}</h3>
				<p>{position}</p>
			</div>
		);
	};
	return (
		<div className={styles.supervisoryBoard}>
			<h2 className={styles.supervisoryTitle}>{supervisoryTitle}</h2>
			<div className={styles.supervisory}>{supervisoryPerson.map(renderPerson)}</div>
		</div>
	);
};
