import PropTypes from 'prop-types';
import { useCallback, useEffect, useLayoutEffect } from 'react';

export const useLockBodyScroll = shouldWork => {
	useLayoutEffect(() => {
		if (shouldWork) {
			const originalStyle = window.getComputedStyle(document.body).overflow;
			document.body.style.overflow = 'hidden';
			return () => (document.body.style.overflow = originalStyle);
		}
		return () => ({});
	}, [shouldWork]);
};

useLockBodyScroll.propTypes = {
	shouldWork: PropTypes.bool.isRequired,
};

export const useCloseOnOutsideClick = (containerRef, closeContainerFn, disableForTargetClassName, deps = []) => {
	const handleOutsideClick = useCallback(
		e => {
			if (containerRef.current && !containerRef.current.contains(e.target)) {
				if (disableForTargetClassName && e.target.className.includes(disableForTargetClassName)) {
					return;
				}
				closeContainerFn();
			}
		},
		[containerRef, closeContainerFn, disableForTargetClassName]
	);

	useEffect(() => {
		window.addEventListener('click', handleOutsideClick);

		return () => window.removeEventListener('click', handleOutsideClick);
	}, [...deps, handleOutsideClick]);
};

useCloseOnOutsideClick.propTypes = {
	containerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]).isRequired,
	closeContainerFn: PropTypes.func.isRequired,
	disableForTargetClassName: PropTypes.string,
	deps: PropTypes.arrayOf(
		PropTypes.oneOfType([PropTypes.boolean, PropTypes.string, PropTypes.number, PropTypes.function])
	),
};
