import React from 'react';

import Image from '../Image/Image';
import styles from './RichTextImage.module.scss';

const RichTextImage = ({ node }) => {
	const { photoFluid, photoFluidPreview } = node.data.target;
	const { aspectRatio } = photoFluid;
	const isImageVertical = aspectRatio < 1;
	return (
		<div className={styles.imageWrapper}>
			<Image
				className={[styles.image, isImageVertical && styles.image_vertical]}
				photo={{
					fluid: photoFluid,
				}}
				alt="story photo"
				previewPhoto={{ fluid: photoFluidPreview }}
			/>
		</div>
	);
};

export default RichTextImage;
