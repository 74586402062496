import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import React from 'react';

import RichTextImage from './RichTextImage/RichTextImage';
import RichTextVideo from './RichTextVideo/RichTextVideo';

const Bold = ({ children }) => <span style={{ fontWeight: 'bold' }}>{children}</span>;
const Text = ({ children }) => <p>{children}</p>;
const Link = ({ node, children }) => (
	<a href={node.data.uri} target="_blank" rel="noreferrer">
		{children}
	</a>
);

const options = {
	renderMark: {
		[MARKS.BOLD]: text => <Bold>{text}</Bold>,
	},
	renderNode: {
		[BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
		[BLOCKS.EMBEDDED_ASSET]: node => <RichTextImage node={node} />,
		[BLOCKS.EMBEDDED_ENTRY]: node => <RichTextVideo node={node} />,
		[INLINES.HYPERLINK]: (node, children) => <Link node={node}>{children}</Link>,
	},
	renderText: text => {
		return text
			.split('\n')
			.flatMap((text, i) => [i > 0 && <br key={i} />, text])
			.map(item => (typeof item === 'string' ? item.replace('\\n', '') : item));
	},
};

export default content => {
	return renderRichText(content, options);
};
