import cx from 'classnames';
import debounce from 'lodash/debounce';
import React, { useLayoutEffect, useState } from 'react';

import Icon from '../../assets/icons/chevron-up.svg';
import styles from './Footer.module.scss';

const ArrowButton = () => {
	const [scrollPosition, setScrollPosition] = useState(0);
	const debouncedFunction = debounce(() => {
		const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
		setScrollPosition(scrollTop);
	}, 500);

	useLayoutEffect(() => {
		window.addEventListener('scroll', debouncedFunction);
		return () => window.removeEventListener('scroll', debouncedFunction);
	});

	const scrollToTop = () => {
		window.scroll({
			top: 0,
			left: 0,
			behavior: 'smooth',
		});
	};

	const active = !!scrollPosition;
	return (
		<button
			className={cx(styles.arrowButton, active && styles.arrowButtonActive)}
			disabled={!active}
			onClick={scrollToTop}>
			<Icon />
		</button>
	);
};

export default ArrowButton;
