import { DESKTOP_HEADER_BREAKPOINT, DESKTOP_HEADER_HEIGHT, HEADER_NAV_DATA, MOBILE_HEADER_HEIGHT } from './constants';
import { extractRouteAndSubrouteData } from './mappers';

export const findNavigationObjectFromUrl = url => {
	return Object.values(HEADER_NAV_DATA).find(v => {
		if (typeof v.route === 'string' && v.route.startsWith('/') && v.route !== '/') {
			return url.split('/')[2] === v.route.split('/')[1] || url.split('/')[1] === v.route.split('/')[1];
		}
		return undefined;
	});
};

export const chunk = (data, chunkSize = 5) => {
	return data.reduce((acc, item, index) => {
		const chunkIndex = Math.floor(index / chunkSize);

		if (!acc[chunkIndex]) {
			// Start new chunk
			acc[chunkIndex] = [];
		}
		acc[chunkIndex].push(item);
		return acc;
	}, []);
};

export const shuffle = array => {
	for (let i = array.length - 1; i > 0; i--) {
		const j = Math.floor(Math.random() * i);
		const temp = array[i];
		array[i] = array[j];
		array[j] = temp;
	}
	return array;
};

export const groupCountriesByYear = countries => {
	const yearMap = countries.reduce((acc, country) => {
		const { name, code, year, flag } = country;
		const currentYearData = acc[year] || [];
		if (!flag.fluid) {
			return {
				...acc,
			};
		}
		return {
			...acc,
			[year]: [
				...currentYearData,
				{
					name,
					code,
					year,
					flagSrc: flag.fluid.src,
				},
			],
		};
	}, {});

	const yearList = Object.values(yearMap);
	return yearList;
};

export const copyToClipboard = text => {
	try {
		const temporaryElement = document.createElement('textarea');
		temporaryElement.value = text;
		temporaryElement.setAttribute('readonly', '');
		temporaryElement.style = { position: 'absolute', left: '-9999px' };
		document.body.appendChild(temporaryElement);
		temporaryElement.select();
		document.execCommand('copy');
		document.body.removeChild(temporaryElement);
		return true;
	} catch (error) {
		return false;
	}
};

export const generateID = () => {
	return '_' + Math.random().toString(36).substr(2, 9);
};

const importAll = r => r.keys().map(r);
const quickNavImages = importAll(require.context('../assets/images/quick-navigation', false, /\.(png|jpe?g|svg)$/));
export const getQuickNavImages = () => {
	return shuffle(quickNavImages);
};

const setMainElementPaddingTop = paddingValue =>
	(document.querySelector('#main').style.paddingTop = `${paddingValue}px`);

export const adjustMainPaddingDependingOnScreenWidth = (additionalDesktopPadding = 0) => {
	return window.innerWidth < DESKTOP_HEADER_BREAKPOINT
		? setMainElementPaddingTop(MOBILE_HEADER_HEIGHT)
		: setMainElementPaddingTop(DESKTOP_HEADER_HEIGHT + additionalDesktopPadding);
};

export const filterThroughHeaderDataForSpecificSections = sections =>
	extractRouteAndSubrouteData().filter(({ translationKey }) => sections.includes(translationKey));
