import PropTypes from 'prop-types';

const { shape, string } = PropTypes;

export const countryLinkPropTypes = shape({
	brand: string,
	countryName: string,
	countryCode: string,
	continent: string,
	title: string,
	link: string,
	customerServiceLink: string,
	flag: string,
});

export const pressReleasePropTypes = {
	title: string.isRequired,
	description: string,
	fileUrl: string,
	fileName: string,
	date: string.isRequired,
};
