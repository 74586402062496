import PropTypes from 'prop-types';
import React from 'react';

import { countryLinkPropTypes } from '../../utils';
import FlagLink from './FlagLink';
import styles from './FlagLink.module.scss';

const FlagLinkColumn = ({ data }) => {
	const renderFlagLink = (item, index) => <FlagLink key={index} data={item} />;

	return <ul className={styles.flagLinkColumn}>{data.map(renderFlagLink)}</ul>;
};

FlagLinkColumn.propTypes = {
	data: PropTypes.arrayOf(countryLinkPropTypes),
};

export default FlagLinkColumn;
