import { filterThroughHeaderDataForSpecificSections } from './functions';

export const isProduction = process.env.NODE_ENV === 'production';

export const CURRENT_LOCALES = {
	en: 'en',
	de: 'de',
};

export const INVESTOR_RELATIONS_URL = locale =>
	isProduction
		? `http://ir.hellofreshgroup.com/websites/hellofresh/${
				locale === CURRENT_LOCALES.en ? 'English' : 'German'
		  }/0/home.html`
		: `https://irpages2-preview.eqs.com/websites/hellofresh20/${
				locale === CURRENT_LOCALES.en ? 'English' : 'German'
		  }/0.html`;
export const GOVERNANCE_URL = locale =>
	isProduction
		? `http://ir.hellofreshgroup.com/websites/hellofresh/${
				locale === CURRENT_LOCALES.en ? 'English' : 'German'
		  }/4000/corporate-governance.html`
		: `https://irpages2-preview.eqs.com/websites/hellofresh20/${
				locale === CURRENT_LOCALES.en ? 'English' : 'German'
		  }/4000/corporate-governance.html`;
export const TECHBLOG_URL = 'https://engineering.hellofresh.com/';
export const HELLOFRESH_CAREERS_URL = 'https://www.hellofresh.com/careers';

const newsroomRoute = '/newsroom';
const mediaGallery = '/press-material';

export const GALLERY_SUBSECTIONS = {
	products: 'products',
	brand: 'brand',
	managementBoard: 'managementBoard',
	factsheet: 'factsheet',
	supplyChain: 'supplyChain',
};

export const ROUTES = {
	aboutUs: '/',
	newsroom: {
		index: newsroomRoute,
		pressReleases: `${newsroomRoute}/press-releases`,
		stories: `${newsroomRoute}/stories`,
		techBlog: `${newsroomRoute}/tech-blog`,
		mediaGallery: `${newsroomRoute}${mediaGallery}`,
		mediaGallerySubsections: {
			[GALLERY_SUBSECTIONS.products]: `${newsroomRoute}${mediaGallery}/products`,
			[GALLERY_SUBSECTIONS.brand]: `${newsroomRoute}${mediaGallery}/brand`,
			[GALLERY_SUBSECTIONS.managementBoard]: `${newsroomRoute}${mediaGallery}/management-board`,
			[GALLERY_SUBSECTIONS.factsheet]: `${newsroomRoute}${mediaGallery}/factsheet`,
			[GALLERY_SUBSECTIONS.supplyChain]: `${newsroomRoute}${mediaGallery}/supply-chain`,
		},
		contact: `${newsroomRoute}/contact`,
	},
	sustainability: {
		index: '/esg',
		ourPlanet: 'planet',
		ourCommunity: 'community',
		ourPeople: 'people',
		ourStandards: 'standards',
		ourWhistleblowerSystem: 'whistleblower',
	},
	investorRelations: {
		shares: locale => `http://ir.hellofreshgroup.com/websites/hellofresh/${locale}/1000/share.html`,
		publications: locale => `http://ir.hellofreshgroup.com/websites/hellofresh/${locale}/2000/publications.html`,
		annualGeneralMeeting: locale => `http://ir.hellofreshgroup.com/websites/hellofresh/${locale}/5000.html`,
		news: locale => `http://ir.hellofreshgroup.com/websites/hellofresh/${locale}/3000/news.html`,
		governance: locale => `http://ir.hellofreshgroup.com/websites/hellofresh/${locale}}/4000/corporate-governance.html`,
		financialCalendar: locale => `http://ir.hellofreshgroup.com/websites/hellofresh/${locale}/6000.html`,
		convertibleBonds: locale =>
			`http://ir.hellofreshgroup.com/websites/hellofresh/${locale}/7000/convertible-bond.html`,
		irServices: isProduction
			? 'http://ir.hellofreshgroup.com/websites/hellofresh/English/9000/ir-services.html'
			: 'https://irpages2-preview.eqs.com/websites/hellofresh20/English/9000/ir-services.html',
	},
};

export const convertLocalesToEQSUrlValues = intlLocale => (intlLocale === CURRENT_LOCALES.en ? 'English' : 'German');

export const HEADER_NAV_DATA = {
	aboutUs: {
		translationKey: 'about_us',
		route: ROUTES.aboutUs,
		descTranslationKey: 'about_us_description',
		buttonTranslationKey: 'read_more',
	},
	newsroom: {
		translationKey: 'newsroom',
		route: ROUTES.newsroom.index,
		subRoutes: [
			{
				translationKey: 'press_releases',
				route: ROUTES.newsroom.pressReleases,
				descTranslationKey: 'press_releases_description',
				buttonTranslationKey: 'read_more',
			},
			{
				translationKey: 'stories',
				route: ROUTES.newsroom.stories,
				descTranslationKey: 'stories_description',
				buttonTranslationKey: 'read_more',
			},
			{
				translationKey: 'tech_blog',
				isCustomLink: true,
				route: TECHBLOG_URL,
			},
			{
				translationKey: 'downloads',
				route: ROUTES.newsroom.mediaGallery,
			},
			{
				translationKey: 'contact',
				route: ROUTES.newsroom.contact,
				descTranslationKey: 'contact_description',
				buttonTranslationKey: 'contact_us',
			},
		],
		descTranslationKey: 'newsroom_description',
		buttonTranslationKey: 'read_more',
	},
	sustainability: {
		translationKey: 'sustainability',
		route: ROUTES.sustainability.index,
		subRoutes: [
			{ translationKey: 'our_planet', route: '#planet', isCustomLink: true, isAnchor: true },
			{ translationKey: 'our_people', route: '#people', isCustomLink: true, isAnchor: true },
			{ translationKey: 'our_community', route: '#community', isCustomLink: true, isAnchor: true },
			{ translationKey: 'our_standards', route: '#standards', isCustomLink: true, isAnchor: true },
			{ translationKey: 'our_whistleblower_system', route: '#whistleblower', isCustomLink: true, isAnchor: true },
		],
		descTranslationKey: 'sustainability_description',
		buttonTranslationKey: 'read_more',
	},
	investorRelations: {
		isCustomLink: true,
		route: locale => INVESTOR_RELATIONS_URL(locale),
		translationKey: 'investor_relations',
		descTranslationKey: 'investor_relations_description',
		buttonTranslationKey: 'read_more',
	},
};

export const NAV_DATA = {
	...HEADER_NAV_DATA,
	mediaGallery: {
		translationKey: 'media_gallery',
		route: ROUTES.newsroom.mediaGallery,
		descTranslationKey: 'media_gallery_description',
		buttonTranslationKey: 'have_a_look',
	},
};

export const GALLERY_NAV = [
	{
		name: GALLERY_SUBSECTIONS.products,
		translationKey: 'products',
		route: ROUTES.newsroom.mediaGallerySubsections.products,
	},
	{
		name: GALLERY_SUBSECTIONS.brand,
		translationKey: 'brand',
		route: ROUTES.newsroom.mediaGallerySubsections.brand,
	},
	{
		name: GALLERY_SUBSECTIONS.managementBoard,
		translationKey: 'management_board',
		route: ROUTES.newsroom.mediaGallerySubsections.managementBoard,
	},
	{
		name: GALLERY_SUBSECTIONS.factsheet,
		translationKey: 'factsheet',
		route: ROUTES.newsroom.mediaGallerySubsections.factsheet,
	},
	{
		name: GALLERY_SUBSECTIONS.supplyChain,
		translationKey: 'supply_chain',
		route: ROUTES.newsroom.mediaGallerySubsections.supplyChain,
	},
];

export const QUICK_NAVIGATION_NEWSROOM = filterThroughHeaderDataForSpecificSections(['contact', 'media_gallery']);

export const QUICK_NAVIGATION_PRESS_RELEASES = filterThroughHeaderDataForSpecificSections([
	'stories',
	'media_gallery',
	'contact',
]);

export const QUICK_NAVIGATION_STORIES = filterThroughHeaderDataForSpecificSections([
	'press_releases',
	'media_gallery',
	'contact',
]);

export const QUICK_NAVIGATION_GALLERY = filterThroughHeaderDataForSpecificSections([
	'press_releases',
	'stories',
	'contact',
]);

export const QUICK_NAVIGATION_CONTACT = [
	...filterThroughHeaderDataForSpecificSections(['stories']),
	HEADER_NAV_DATA.newsroom,
	HEADER_NAV_DATA.sustainability,
];

export const QUICK_NAVIGATION_SUSTAINABILITY = [
	...filterThroughHeaderDataForSpecificSections(['press_releases', 'stories']),
	HEADER_NAV_DATA.investorRelations,
];

export const QUICK_NAVIGATION_404 = [HEADER_NAV_DATA.aboutUs, HEADER_NAV_DATA.newsroom, HEADER_NAV_DATA.sustainability];

export const BRAND_HELLO_FRESH = 'HelloFresh';
export const BRAND_GREEN_CHEF = 'Green Chef';
export const BRAND_EVERY_PLATE = 'EveryPlate';
export const BRAND_CHEFS_PLATE = 'Chefs Plate';
export const BRAND_FACTOR = 'Factor';
export const BRAND_YOUFOODZ = 'Youfoodz';
export const BRAND_PETS_TABLE = 'Pets Table';
export const BRAND_GOOD_CHOP = 'Good Chop';

export const BRANDS = {
	HELLO_FRESH: BRAND_HELLO_FRESH,
	GREEN_CHEF: BRAND_GREEN_CHEF,
	EVERY_PLATE: BRAND_EVERY_PLATE,
	CHEFS_PLATE: BRAND_CHEFS_PLATE,
	FACTOR: BRAND_FACTOR,
	YOUFOODZ: BRAND_YOUFOODZ,
	PETS_TABLE: BRAND_PETS_TABLE,
	GOOD_CHOP: BRAND_GOOD_CHOP,
};

export const POPOVER_CUSTOMER_SERVICE = 'customerService';
export const POPOVER_GET_COOKING = 'getCooking';
export const POPOVER_GET_COOKING_BRAND = 'getCookingBrand';

export const POPOVERS = {
	CUSTOMER_SERVICE: POPOVER_CUSTOMER_SERVICE,
	GET_COOKING: POPOVER_GET_COOKING,
	GET_COOKING_BRAND: POPOVER_GET_COOKING_BRAND,
};

export const CONTENTFUL_STORY_PHOTO_TYPE = {
	noPhoto: 'NO_PHOTO',
	widePhoto: 'WIDE_PHOTO',
	highPhoto: 'HIGH_PHOTO',
};

// TODO: potentially use scss variables in js, see here: https://github.com/gatsbyjs/gatsby/issues/3607#issuecomment-504879759
export const DESKTOP_HEADER_BREAKPOINT = 1150;
export const DESKTOP_HEADER_HEIGHT = 110;
export const DESKTOP_SUBNAVIGATION_HEIGHT = 48;
export const DESKTOP_HEADER_OPTIONS_BREAKPOINT = -20;
export const MOBILE_HEADER_HEIGHT = 64;

export const GALLERY_TYPES = {
	management: 'management',
	brand: 'brand',
	documents: 'documents',
	default: 'default',
};

export const FORMATTED_DATE_OPTIONS = {
	year: 'numeric',
	month: 'long',
	day: 'numeric',
};

// this constant isn't used anywhere in the app
// serves only as a documentation for possible contentful page key values
export const CONTENTFUL_META_DATA_PAGE_KEYS = {
	home: 'home',
	pressReleases: 'press-releases',
};
