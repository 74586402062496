import 'react-responsive-carousel/lib/styles/carousel.min.css';

import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { Slider } from '../../index';
import styles from './development.module.scss';

export const DevelopmentCarousel = ({ data }) => {
	const [selectedIndex, setSelectedIndex] = useState(null);
	const renderCountry = (country, index) => {
		const { name, year, flagSrc } = country;
		return (
			<div
				key={`about-development-country-${index}`}
				index={index}
				className={cx(styles.country, selectedIndex === index && styles.selectedCountry)}>
				<div className={styles.flagWrapper}>
					<img src={flagSrc} alt={`${name} flag`} />
				</div>
				<span>{year}</span>
			</div>
		);
	};

	const itemRenderer = (item, options) => {
		const { isSelected } = options;
		const { index } = item.props;
		if (isSelected) {
			return <div className={styles.itemTransformer}>{item}</div>;
		}
		if (Math.abs(index - selectedIndex) === 1) {
			return <div className={cx(styles.itemTransformer, styles.itemTransformerDirectNeighbour)}>{item}</div>;
		}
		return <div className={cx(styles.itemTransformer, styles.itemTransformerNeighbour)}>{item}</div>;
	};

	const updateSelectedIndex = index => {
		setSelectedIndex(index);
	};

	return (
		<Slider
			className={styles.developmentCarousel}
			nextArrowClassName={styles.nextArrow}
			prevArrowClassName={styles.prevArrow}
			showIndicators={false}
			showStatus={false}
			showThumbs={false}
			infiniteLoop={false}
			centerMode={true}
			centerSlidePercentage={20}
			renderItem={itemRenderer}
			// On change is only called on swipe, and not on click
			onChange={updateSelectedIndex}
			onClickItem={updateSelectedIndex}>
			{data.flatMap(country => country).map(renderCountry)}
		</Slider>
	);
};

DevelopmentCarousel.propTypes = {
	data: PropTypes.arrayOf(
		PropTypes.arrayOf(
			PropTypes.shape({
				name: PropTypes.string.isRequired,
				year: PropTypes.number.isRequired,
				flagSrc: PropTypes.string.isRequired,
			})
		)
	),
};
