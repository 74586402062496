import React from 'react';

import styles from './RichTextVideo.module.scss';

const instaReg = new RegExp(/www\.instagram\.com\//);
const ytReg = new RegExp(/www\.youtube\.com\/watch\?v=|youtu\.be\//);

const getUrl = target => {
	const { videoUrl, asset } = target || {};
	return videoUrl ? videoUrl : asset?.file?.url;
};

const getVideo = target => {
	const url = getUrl(target);
	return url ? (
		isYT(url) || instaReg.test(url) ? (
			<iframe
				className={isYT(url) ? styles.video : styles.instaVideo}
				src={makeSrc(url)}
				title={target.title}
				allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
				frameBorder="0"
				webkitallowfullscreen="true"
				mozallowfullscreen="true"
				allowFullScreen
			/>
		) : (
			<video controls="controls" className={styles.video}>
				<source src={makeSrc(url)} />
			</video>
		)
	) : null;
};

const isInsta = url => {
	return instaReg.test(url);
};

const isYT = url => {
	return ytReg.test(url);
};

const makeSrc = url => {
	return isYT(url) || isInsta(url)
		? isYT(url)
			? url.replace(ytReg, 'youtube.com/embed/').split('&')[0]
			: url.split('/?')[0] + '/embed'
		: url;
};

const RichTextVideo = ({ node }) => {
	const { target } = node.data;
	return <div className={styles.videoWrapper}>{getVideo(target)}</div>;
};

export default RichTextVideo;
