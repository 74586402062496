/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';

// TODO: make title use translations aswell
function SEO({ description, lang, title, pathname, metaImage, children, shouldShowTitleTemplate = true }) {
	const { site } = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						title
						description
						author
					}
				}
			}
		`
	);

	const metaDescription = description || site.siteMetadata.description;
	const localTitle = shouldShowTitleTemplate ? `${title} | ${site.siteMetadata.title}` : title;
	return (
		<Helmet
			htmlAttributes={{
				lang,
			}}
			title={localTitle}>
			<meta property="og:title" name="title" content={localTitle} />
			<meta property="og:description" name="description" content={metaDescription} />
			{metaImage && <meta property="og:image" name="image" content={metaImage} />}
			{/*<meta property="og:url" content={location.href} />*/}
			<meta property="og:type" content="website" />
			<meta name="twitter:card" content={metaImage ? 'summary_large_image' : 'summary'} />
			{children}
		</Helmet>
	);
}

SEO.defaultProps = {
	lang: `en`,
	meta: [],
	description: ``,
};

SEO.propTypes = {
	description: PropTypes.string,
	lang: PropTypes.string,
	meta: PropTypes.arrayOf(PropTypes.object),
	children: PropTypes.node,
	metaImage: PropTypes.string,
	pathname: PropTypes.string,
	title: PropTypes.string.isRequired,
};

export default SEO;
