import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import { useIntl } from 'gatsby-plugin-intl';
import React from 'react';

import LinkedinIcon from '../../../assets/icons/linkedin-footer.svg';
import { GOVERNANCE_URL, getLocalizedDataFromContentfulEdges } from '../../../utils';
import styles from './management.module.scss';

export const Management = () => {
	const intl = useIntl();
	const data = useStaticQuery(graphql`
		{
			allContentfulManagementBoard {
				edges {
					node {
						node_locale
						title
						linkTitle
						management {
							name
							position
							photo {
								fluid {
									...GatsbyContentfulFluid
								}
							}
							linkedinUrl
						}
					}
				}
			}
		}
	`);
	const {
		node: { title, management, linkTitle },
	} = getLocalizedDataFromContentfulEdges(data.allContentfulManagementBoard.edges, intl.locale)[0];

	const renderPerson = (person, index) => {
		const { name, position, photo = null, linkedinUrl = null } = person;
		return (
			<div key={`about-management-${index}`} className={styles.managementPerson}>
				<a
					className={styles.managementPersonLink}
					href={linkedinUrl}
					aria-label={`${name} LinkedIn`}
					target="_blank"
					rel="noreferrer">
					{linkedinUrl && (
						<div className={styles.linkedinOverlay}>
							<LinkedinIcon className={styles.linkedinIcon} />
						</div>
					)}
					{photo && photo.fluid && <Img fluid={photo.fluid} alt={name} />}
				</a>
				<h3>{name}</h3>
				<p>{position}</p>
			</div>
		);
	};
	return (
		<div className={styles.managementBoard}>
			<h2>{title}</h2>
			<div className={styles.management}>{management.map(renderPerson)}</div>
			<div className={styles.managementLinkWrapper}>
				<a className={styles.managementLink} href={GOVERNANCE_URL(intl.locale)}>
					{linkTitle}
				</a>
			</div>
		</div>
	);
};
