import {
	BRAND_CHEFS_PLATE,
	BRAND_EVERY_PLATE,
	BRAND_FACTOR,
	BRAND_GOOD_CHOP,
	BRAND_GREEN_CHEF,
	BRAND_HELLO_FRESH,
	BRAND_PETS_TABLE,
	BRAND_YOUFOODZ,
	GALLERY_NAV,
	NAV_DATA,
} from './constants';

export const getCountryLinksData = initialData => {
	return initialData.map(value => ({
		...value,
		brand: value.brand.name,
		countryName: value.country.name,
		countryCode: value.country.code,
		continent: value.country.continent,
		link: value.link,
		customerServiceLink: value.customerServiceLink,
		flag: value.country.flag.fluid.src,
	}));
};

export const getBrandPopoverData = (initialData, brandName) => {
	return initialData
		.reduce((acc, countryLink) => {
			return countryLink.brand === brandName ? [...acc, countryLink] : acc;
		}, [])
		.map(value => ({
			countryName: value.countryName,
			countryCode: value.countryCode,
			title: value.countryCode,
			link: value.link,
			flag: value.flag,
		}));
};

export const getBrandTabPopoverData = initialData => {
	return initialData.map(value => ({
		brand: value.brand,
		countryName: value.countryName,
		countryCode: value.countryCode,
		title: value.countryCode,
		link: value.link,
		flag: value.flag,
	}));
};

export const getCustomerServicePopoverData = initialData => {
	return initialData.map(value => ({
		brand: value.brand,
		countryName: value.countryName,
		countryCode: value.countryCode,
		title: value.countryCode,
		link: value.customerServiceLink,
		flag: value.flag,
	}));
};

export const mapFlagInformationToBrands = flagsArray => {
	const result = {};
	result[BRAND_HELLO_FRESH] = flagsArray.filter(f => f.brand === BRAND_HELLO_FRESH);
	result[BRAND_CHEFS_PLATE] = flagsArray.filter(f => f.brand === BRAND_CHEFS_PLATE);
	result[BRAND_GREEN_CHEF] = flagsArray.filter(f => f.brand === BRAND_GREEN_CHEF);
	result[BRAND_EVERY_PLATE] = flagsArray.filter(f => f.brand === BRAND_EVERY_PLATE);
	result[BRAND_FACTOR] = flagsArray.filter(f => f.brand === BRAND_FACTOR);
	result[BRAND_YOUFOODZ] = flagsArray.filter(f => f.brand === BRAND_YOUFOODZ);
	result[BRAND_PETS_TABLE] = flagsArray.filter(f => f.brand === BRAND_PETS_TABLE);
	result[BRAND_GOOD_CHOP] = flagsArray.filter(f => f.brand === BRAND_GOOD_CHOP);
	return result;
};

export const getPressReleasesListData = initialData => {
	return initialData
		.filter(({ node }) => !!node.title)
		.map(({ node }) => {
			const { description } = node.description || {};
			const { file: { url, fileName } = {} } = node.pdf || {};
			return {
				title: node.title,
				description,
				fileUrl: `https:${url}`,
				fileName,
				date: node.date,
			};
		});
};

export const getGalleryMainListData = initialData => {
	const order = GALLERY_NAV.map(section => `${section.route}`.split('/')[3]);
	const mappedData = initialData.map(({ node }) => {
		const backgroundSrc = node.background.fixed && node.background.fixed.src;
		const pdfUrl =
			(node.assets[0]?.__typename === 'ContentfulGalleryDocument' && node.assets[0].file?.file?.url) || undefined;
		return {
			name: node.name,
			path: node.path,
			packageUrl: node.packageUrl,
			pdfUrl,
			backgroundSrc,
		};
	});
	return order.map(path => mappedData.find(item => item.path === path));
};

export const getGallerySectionListData = initialData => {
	return initialData.reduce((acc, value) => {
		const { name, description, photo, logo } = value;
		const { fluid, file } = photo || logo || {};
		const { fileName, url } = file || {};
		if (!fluid) {
			return acc;
		}
		return [
			...acc,
			{
				name,
				description,
				image: {
					fluid,
					url: `https:${url}`,
					fileName,
				},
			},
		];
	}, []);
};

export const getLocalizedDataFromContentfulEdges = (data, locale) => {
	if (data.length === 0) {
		return undefined;
	}
	return data.filter(({ node }) => node.node_locale.substring(0, 2) === locale);
};

export const extractRouteAndSubrouteData = () => {
	return Object.values(NAV_DATA)
		.flatMap(d => {
			if (d.subRoutes !== undefined) {
				return [d, d.subRoutes].flat();
			}
			return d;
		})
		.filter(data => data.descTranslationKey !== undefined);
};

export const getLatestStories = (initialData, amount) => {
	return initialData.reduce((acc, { node }) => {
		if (!node.title || acc.length >= amount) {
			return acc;
		}
		return [...acc, node];
	}, []);
};

export const getLatestReleases = initialData => {
	return initialData.reduce((acc, { node }) => {
		if (!(node.title && node.pdf) || acc.length >= 5) {
			return acc;
		}
		return [...acc, node];
	}, []);
};
