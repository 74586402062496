import { useLocation } from '@reach/router';
import cx from 'classnames';
import { Link, changeLocale, useIntl } from 'gatsby-plugin-intl';
import throttle from 'lodash/throttle';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import Popover from 'react-tiny-popover';

import Arrow from '../../assets/icons/expand-more.svg';
import Logo from '../../assets/logo/hellofresh-group.svg';
import {
	CURRENT_LOCALES,
	DESKTOP_HEADER_BREAKPOINT,
	DESKTOP_SUBNAVIGATION_HEIGHT,
	HEADER_NAV_DATA,
	HELLOFRESH_CAREERS_URL,
	ROUTES,
	adjustMainPaddingDependingOnScreenWidth,
	findNavigationObjectFromUrl,
	mapFlagInformationToBrands,
} from '../../utils';
import { FlagBrands } from '../';
import { ContentLayout } from '../ContentLayout';
import styles from './DesktopHeader.module.scss';
import { DesktopSubNav } from './DesktopSubNav';

export const DesktopHeader = ({ popoverData, shouldHideOptions }) => {
	const intl = useIntl();
	const location = useLocation();
	const [activeNavItem, setActiveNavItem] = useState(findNavigationObjectFromUrl(location.pathname));
	const [isPopoverOpen, setIsPopoverOpen] = useState(false);
	const adjustPadding = () => {
		const isSubnavActive = activeNavItem && activeNavItem.subRoutes !== undefined;
		adjustMainPaddingDependingOnScreenWidth();
		if (isSubnavActive && window.innerWidth > DESKTOP_HEADER_BREAKPOINT) {
			adjustMainPaddingDependingOnScreenWidth(DESKTOP_SUBNAVIGATION_HEIGHT);
		}
	};

	const throttledListener = throttle(() => {
		adjustPadding();
	}, 300);

	useEffect(() => {
		if (shouldHideOptions && isPopoverOpen) {
			setIsPopoverOpen(false);
		}
	}, [shouldHideOptions, isPopoverOpen]);

	// needed to change main element padding based on screen width
	useLayoutEffect(() => {
		adjustPadding();
		window.addEventListener('resize', throttledListener);
		return () => window.removeEventListener('resize', throttledListener);
	}, []);

	const renderNavigationList = () => {
		return Object.values(HEADER_NAV_DATA).map(navData => {
			const { hidden, translationKey, route, isCustomLink } = navData;
			if (hidden) {
				return null;
			}
			if (isCustomLink) {
				return (
					<li key={translationKey}>
						<a href={route(intl.locale)}>{intl.formatMessage({ id: `nav.${translationKey}` })}</a>
					</li>
				);
			}
			return (
				<li key={translationKey}>
					<Link
						to={route}
						activeClassName={styles.active}
						onClick={() => setActiveNavItem(navData)}
						className={cx(activeNavItem && activeNavItem.translationKey === translationKey && styles.active)}>
						{intl.formatMessage({ id: `nav.${translationKey}` })}
					</Link>
				</li>
			);
		});
	};

	const renderFlagsPopover = () => {
		const flagData = mapFlagInformationToBrands(popoverData);
		return <FlagBrands flagData={flagData} />;
	};

	return (
		<>
			<section className={styles.container}>
				<ContentLayout className={styles.wrapper}>
					<section className={styles.navigation}>
						<Link className={styles.logoLink} to={ROUTES.aboutUs}>
							<Logo className={styles.logo} alt="logo" />
						</Link>
						<nav>
							<ul className={styles.mainNav}>{renderNavigationList()}</ul>
						</nav>
					</section>
					<section className={cx(styles.options, shouldHideOptions && styles.hide)}>
						<div>
							<Popover
								isOpen={isPopoverOpen}
								onClickOutside={() => setIsPopoverOpen(false)}
								containerClassName={styles.flagsPopover}
								position={'bottom'}
								content={renderFlagsPopover()}>
								<span onClick={() => setIsPopoverOpen(!isPopoverOpen)}>
									{intl.formatMessage({ id: 'nav.get_cooking' })} <Arrow />
								</span>
							</Popover>
						</div>
						<div>
							<a target="_blank" rel="noreferrer" href={HELLOFRESH_CAREERS_URL}>
								{intl.formatMessage({ id: 'nav.careers' })}
							</a>
						</div>
						<div>
							<span
								className={cx(intl.locale === CURRENT_LOCALES.de && styles.languageActive)}
								onClick={() => changeLocale(CURRENT_LOCALES.de)}>
								{CURRENT_LOCALES.de}
							</span>
							/
							<span
								className={cx(intl.locale === CURRENT_LOCALES.en && styles.languageActive)}
								onClick={() => changeLocale(CURRENT_LOCALES.en)}>
								{CURRENT_LOCALES.en}
							</span>
						</div>
					</section>
				</ContentLayout>
			</section>
			{!shouldHideOptions && <DesktopSubNav activeNavItem={activeNavItem} />}
		</>
	);
};
