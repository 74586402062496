import PropTypes from 'prop-types';
import React from 'react';
import ReactTooltip from 'react-tooltip';

import { generateID } from '../../utils';
import styles from './tooltip.module.scss';

const Tooltip = ({ children, text, place }) => {
	const id = generateID();
	return (
		<div className={styles.tooltip} data-tip={text} data-for={id}>
			{children}
			<ReactTooltip id={id} place={place} type="light" effect="solid" />
		</div>
	);
};

Tooltip.propTypes = {
	children: PropTypes.node.isRequired,
	text: PropTypes.string.isRequired,
	place: PropTypes.string,
};

Tooltip.defaultProps = {
	place: 'bottom',
};

export default Tooltip;
