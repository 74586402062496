import { useIntl } from 'gatsby-plugin-intl';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import BottomSlideModal from '../../ui/BottomSlideModal/BottomSlideModal';
import { chunk, countryLinkPropTypes } from '../../utils';
import FlagLinkColumn from '../FlagLink/FlagLinkColumn';
import Popover from './Popover';
import styles from './Popover.module.scss';

const GetCookingPopover = ({ children, data }) => {
	const intl = useIntl();
	const [isMobileModalOpen, setIsMobileModalOpen] = useState(false);

	const renderColumn = (column, index) => <FlagLinkColumn key={index} data={column} />;

	const chunkedData = chunk(data);
	const content = chunkedData.map(renderColumn);

	const handleMobileModalToggle = event => {
		event.stopPropagation();
		setIsMobileModalOpen(!isMobileModalOpen);
	};

	return (
		<>
			<div className={styles.modalContainer}>
				<BottomSlideModal
					title={intl.formatMessage({ id: 'modal.select_location' })}
					content={<div className={styles.getCookingModalBody}>{content}</div>}
					isVisible={isMobileModalOpen}
					closeFn={() => setIsMobileModalOpen(false)}
				/>
				<button onClick={handleMobileModalToggle}>{children}</button>
			</div>
			<div className={styles.popoverContainer}>
				<Popover
					children={children}
					content={<div className={styles.getCookingPopoverBody}>{content}</div>}
					className={styles.getCookingPopover}
					position={'top'}
				/>
			</div>
		</>
	);
};

Popover.propTypes = {
	children: PropTypes.node.isRequired,
	data: PropTypes.arrayOf(countryLinkPropTypes),
};

export default GetCookingPopover;
