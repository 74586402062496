import 'normalize.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import './main.css';
import '../stylesheets/main.scss';

import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import { getCountryLinksData } from '../utils/mappers';
import { Footer, Header } from './';

const Layout = ({ children }) => {
	const data = useStaticQuery(
		graphql`
			query {
				contentfulSection(key: { eq: "key-countries-links" }) {
					content {
						... on ContentfulCountryLinks {
							brand {
								name
							}
							country {
								name
								code
								continent
								flag {
									fluid {
										src
									}
								}
							}
							link
							customerServiceLink
						}
					}
				}
			}
		`
	);
	const {
		contentfulSection: { content },
	} = data;
	const countryLinks = getCountryLinksData(content);

	return (
		<div className="layout">
			<Header countryLinks={countryLinks} />
			<main id="main">{children}</main>
			<Footer countryLinks={countryLinks} />
		</div>
	);
};

Layout.propTypes = {
	children: PropTypes.node.isRequired,
};

export default Layout;
