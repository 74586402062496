import React from 'react';

import { countryLinkPropTypes } from '../../utils';
import styles from './FlagLink.module.scss';

const FlagLink = ({ data }) => {
	return (
		<li className={styles.flagLink}>
			<a href={data.link} target="_blank" rel="noreferrer">
				<div className={styles.flagWrapper}>
					<img src={data.flag} alt={`${data.countryName} flag`} />
				</div>
				{data.title}
			</a>
		</li>
	);
};

FlagLink.propTypes = {
	data: countryLinkPropTypes.isRequired,
};

export default FlagLink;
