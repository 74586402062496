import { graphql, useStaticQuery } from 'gatsby';
import { useIntl } from 'gatsby-plugin-intl';
import React from 'react';

import { getLocalizedDataFromContentfulEdges } from '../../../utils';
import RichTextRenderer from '../../RichTextRenderer';
import styles from './business.module.scss';

export const Business = () => {
	const intl = useIntl();
	const data = useStaticQuery(graphql`
		{
			allContentfulOurBusinessModel {
				edges {
					node {
						node_locale
						youtubeUrl
						readMoreLink
						text {
							raw
						}
					}
				}
			}
		}
	`);
	const { node: business } = getLocalizedDataFromContentfulEdges(
		data.allContentfulOurBusinessModel.edges,
		intl.locale
	)[0];
	return (
		<div className={styles.ourBusiness}>
			<div className={styles.videoContainer}>
				<iframe
					title="youtube video"
					width="740"
					height="420"
					src={`https://www.youtube.com/embed/${business.youtubeUrl.split('v=')[1]}`}
					frameBorder="0"
					allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
					allowFullScreen
				/>
			</div>
			<div className={styles.textWrapper}>
				<div className={styles.businessText}>{RichTextRenderer(business.text)}</div>
				<a href={business.readMoreLink}>{intl.formatMessage({ id: 'read_more' })}</a>
			</div>
		</div>
	);
};
