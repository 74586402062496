import GatsbyImage from 'gatsby-image';
import React, { useEffect } from 'react';

import Animations from '../Animations/Animations';
import styles from './image.module.scss';
import Portal from './ImagePortal';

const ImagePreview = ({ photo, visible, close }) => {
	const { aspectRatio } = photo.fluid || photo.fixed;
	useEffect(() => {
		document.body.style.overflow = visible ? 'hidden' : '';
	}, [visible]);
	let windowWidth = 0;
	let windowHeight = 0;
	if (typeof window !== `undefined`) {
		windowWidth = window.innerWidth;
		windowHeight = window.innerHeight;
	}
	let imageWidth = windowWidth;
	let imageHeight = imageWidth / aspectRatio;
	if (imageHeight > windowHeight) {
		imageHeight = windowHeight;
		imageWidth = imageHeight * aspectRatio;
	}
	const imageStyle = { width: `${imageWidth - 50}px` };
	return (
		<Portal id="image-preview">
			<Animations.FadeInOut visible={visible} duration={200}>
				<div className={styles.imagePreview}>
					<div className={styles.imagePreview__backdrop} onClick={close}></div>
					<div className={styles.imagePreview__imageWrapper} style={imageStyle}>
						<div className={styles.imagePreview__close}>
							<button type="button" onClick={close} className={styles.close}>
								<div className={styles.close__icon} />
							</button>
						</div>
						<GatsbyImage {...photo} />
					</div>
				</div>
			</Animations.FadeInOut>
		</Portal>
	);
};

export default ImagePreview;
