import cx from 'classnames';
import { useIntl } from 'gatsby-plugin-intl';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import {
	BRAND_CHEFS_PLATE,
	BRAND_EVERY_PLATE,
	BRAND_FACTOR,
	BRAND_GOOD_CHOP,
	BRAND_GREEN_CHEF,
	BRAND_HELLO_FRESH,
	BRAND_PETS_TABLE,
	BRAND_YOUFOODZ,
	chunk,
	countryLinkPropTypes,
} from '../../utils';
import FlagLinkColumn from '../FlagLink/FlagLinkColumn';
import styles from './FlagBrands.module.scss';

export const FlagBrands = ({ flagData }) => {
	const intl = useIntl();
	const [activeTab, setActiveTab] = useState(BRAND_HELLO_FRESH);
	const brands = [
		{ key: BRAND_HELLO_FRESH, text: intl.formatMessage({ id: 'nav.hello_fresh' }) },
		{ key: BRAND_GREEN_CHEF, text: intl.formatMessage({ id: 'nav.green_chef' }) },
		{ key: BRAND_EVERY_PLATE, text: intl.formatMessage({ id: 'nav.every_plate' }) },
		{ key: BRAND_CHEFS_PLATE, text: intl.formatMessage({ id: 'nav.chefs_plate' }) },
		{ key: BRAND_FACTOR, text: intl.formatMessage({ id: 'nav.factor' }) },
		{ key: BRAND_YOUFOODZ, text: intl.formatMessage({ id: 'nav.youfoodz' }) },
		{ key: BRAND_PETS_TABLE, text: intl.formatMessage({ id: 'nav.pets_table' }) },
		{ key: BRAND_GOOD_CHOP, text: intl.formatMessage({ id: 'nav.good_chop' }) },
	];
	const renderColumn = (column, index) => <FlagLinkColumn key={index} data={column} />;
	const renderBrandTabs = () => {
		return brands.map(brand => (
			<span
				key={brand.key}
				className={cx(brand.key === activeTab && styles.active)}
				onClick={() => setActiveTab(brand.key)}>
				{brand.text}
			</span>
		));
	};
	return (
		<div className={styles.container}>
			<section className={styles.tabs}>{renderBrandTabs()}</section>
			<section className={styles.flags}>{chunk(flagData[activeTab]).map(renderColumn)}</section>
		</div>
	);
};

FlagBrands.propTypes = {
	flagData: PropTypes.objectOf(PropTypes.arrayOf(countryLinkPropTypes)),
};
