import { graphql, useStaticQuery } from 'gatsby';
import { Link, useIntl } from 'gatsby-plugin-intl';
import PropTypes from 'prop-types';
import React from 'react';

import Icon from '../../assets/icons/expand-more.svg';
import LinkedinIcon from '../../assets/icons/linkedin-footer.svg';
import Logo from '../../assets/logo/hellofresh-group.svg';
import {
	BRANDS,
	HEADER_NAV_DATA,
	POPOVERS,
	ROUTES,
	countryLinkPropTypes,
	getBrandPopoverData,
	getCustomerServicePopoverData,
	getLocalizedDataFromContentfulEdges,
} from '../../utils';
import { ContentLayout } from '../.';
import CustomerServicePopover from '../Popovers/CustomerServicePopover';
import GetCookingPopover from '../Popovers/GetCookingPopover';
import ArrowButton from './ArrowButton';
import styles from './Footer.module.scss';

export const Footer = ({ countryLinks }) => {
	const intl = useIntl();
	const footerData = useStaticQuery(
		graphql`
			query {
				contentfulExternalLinks(type: { eq: "linkedin" }) {
					url
				}
				allContentfulPrivacy {
					edges {
						node {
							node_locale
							file {
								file {
									fileName
									url
								}
							}
						}
					}
				}
			}
		`
	);
	const {
		contentfulExternalLinks: { url: linkedinUrl },
		allContentfulPrivacy: { edges },
	} = footerData;

	const privacy = getLocalizedDataFromContentfulEdges(edges, intl.locale)[0];
	const { file: privacyFile } = privacy.node.file;

	const navCompanyData = {
		titleTranslationKey: 'company',
		items: Object.values(HEADER_NAV_DATA),
	};

	const navContactData = {
		titleTranslationKey: 'contact',
		items: [
			{
				translationKey: 'ir_contact',
				route: ROUTES.investorRelations.irServices,
			},
			{
				translationKey: 'pr_contact',
				route: ROUTES.newsroom.contact,
			},
			{
				translationKey: 'new_suppliers_contact',
				route: 'mailto:newsuppliers@hellofresh.com',
			},
			{
				translationKey: 'customer_service',
				popover: POPOVERS.CUSTOMER_SERVICE,
				popoverData: getCustomerServicePopoverData(countryLinks),
			},
		],
	};

	const navGetCookingData = {
		titleTranslationKey: 'get_cooking',
		items: [
			{
				translationKey: 'hello_fresh',
				popover: POPOVERS.GET_COOKING_BRAND,
				popoverData: getBrandPopoverData(countryLinks, BRANDS.HELLO_FRESH),
			},
			{
				translationKey: 'green_chef',
				popover: POPOVERS.GET_COOKING_BRAND,
				popoverData: getBrandPopoverData(countryLinks, BRANDS.GREEN_CHEF),
			},
			{
				translationKey: 'every_plate',
				popover: POPOVERS.GET_COOKING_BRAND,
				popoverData: getBrandPopoverData(countryLinks, BRANDS.EVERY_PLATE),
			},
			{
				translationKey: 'chefs_plate',
				popover: POPOVERS.GET_COOKING_BRAND,
				popoverData: getBrandPopoverData(countryLinks, BRANDS.CHEFS_PLATE),
			},
			{
				translationKey: 'factor',
				popover: POPOVERS.GET_COOKING_BRAND,
				popoverData: getBrandPopoverData(countryLinks, BRANDS.FACTOR),
			},
			{
				translationKey: 'youfoodz',
				popover: POPOVERS.GET_COOKING_BRAND,
				popoverData: getBrandPopoverData(countryLinks, BRANDS.YOUFOODZ),
			},
			{
				translationKey: 'pets_table',
				popover: POPOVERS.GET_COOKING_BRAND,
				popoverData: getBrandPopoverData(countryLinks, BRANDS.PETS_TABLE),
			},
			{
				translationKey: 'good_chop',
				popover: POPOVERS.GET_COOKING_BRAND,
				popoverData: getBrandPopoverData(countryLinks, BRANDS.GOOD_CHOP),
			},
		],
	};

	function renderNavSection(section) {
		const { titleTranslationKey, items } = section;
		const name = intl.formatMessage({ id: `footer.${titleTranslationKey}` });
		return (
			<div key={`footer-nav-section-${titleTranslationKey}`} className={styles.navSection}>
				<h3 className={styles.navSectionTitle}>{name}</h3>
				<ul className={styles.navList}>{items.map(renderNavItem)}</ul>
			</div>
		);
	}

	function renderNavItem(item) {
		const { translationKey, route, popover, popoverData, isCustomLink } = item;
		const name = intl.formatMessage({ id: `nav.${translationKey}` });
		let itemBody;
		if (popover) {
			itemBody = getPopover(popover, name, popoverData);
		} else {
			if (isCustomLink) {
				itemBody = <a href={route(intl.locale)}>{name}</a>;
			} else {
				itemBody = route.startsWith('/') ? <Link to={route}>{name}</Link> : <a href={route}>{name}</a>;
			}
		}

		return (
			<li key={`footer-nav-item-${name}`} className={styles.navItem}>
				{itemBody}
			</li>
		);
	}

	function getPopover(popoverType, popoverToggleName, popoverData) {
		const popoverToggleBody = (
			<>
				{popoverToggleName}
				<Icon className={styles.expandIcon} />
			</>
		);
		switch (popoverType) {
			case POPOVERS.CUSTOMER_SERVICE:
				return <CustomerServicePopover data={popoverData}>{popoverToggleBody}</CustomerServicePopover>;
			default:
				return <GetCookingPopover data={popoverData}>{popoverToggleBody}</GetCookingPopover>;
		}
	}

	return (
		<footer className={styles.footer}>
			<ContentLayout className={styles.arrowButtonWrapper}>
				<ArrowButton />
			</ContentLayout>
			<div className={styles.footerTop}>
				<ContentLayout className={styles.footerTopContainer}>
					<div className={styles.footerLogoWrapper}>
						<Logo className={styles.footerLogo} alt="logo" />
					</div>
					<nav className={styles.footerNav}>
						<div className={styles.socialBar}>
							{linkedinUrl && (
								<a
									href={linkedinUrl}
									className={styles.linkedinButton}
									aria-label="HelloFresh LinkedIn"
									target="_blank"
									rel="noreferrer">
									<LinkedinIcon className={styles.linkedinIcon} />
								</a>
							)}
						</div>
						{renderNavSection(navCompanyData)}
						{renderNavSection(navContactData)}
						{renderNavSection(navGetCookingData)}
					</nav>
				</ContentLayout>
			</div>
			<div className={styles.footerBottom}>
				<ContentLayout className={styles.footerBottomContainer}>
					<div className={styles.copyright}>&copy; 2020 HelloFresh SE</div>
					<ul className={styles.links}>
						<li>
							<a href={privacyFile.url} download={privacyFile.fileName} target="_blank" rel="noreferrer">
								{intl.formatMessage({ id: 'footer.privacy_policy' })}
							</a>
						</li>
						<li>
							<Link to="/imprint">{intl.formatMessage({ id: 'footer.imprint' })}</Link>
						</li>
					</ul>
				</ContentLayout>
			</div>
		</footer>
	);
};

Footer.propTypes = {
	countryLinks: PropTypes.arrayOf(countryLinkPropTypes),
};
