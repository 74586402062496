import { graphql, useStaticQuery } from 'gatsby';
import { FormattedDate, Link, useIntl } from 'gatsby-plugin-intl';
import React from 'react';

import { FORMATTED_DATE_OPTIONS, ROUTES, getLocalizedDataFromContentfulEdges } from '../../../utils';
import { ContentLayout } from '../../ContentLayout';
import styles from './MobileStories.module.scss';

export const MobileStories = () => {
	const data = useStaticQuery(graphql`
		{
			allContentfulStory(sort: { order: DESC, fields: createdAt }) {
				edges {
					node {
						node_locale
						photo {
							fluid {
								src
							}
						}
						title
						description
						createdAt
						id
						contentful_id
						slug
					}
				}
			}
		}
	`);
	const intl = useIntl();
	const { locale } = intl;
	const stories = getLocalizedDataFromContentfulEdges(data.allContentfulStory.edges, locale);
	const renderStories = () => {
		if (!stories) {
			return null;
		}
		return stories.map(({ node }) => {
			const { id, contentful_id, photo, createdAt, title, slug } = node;
			const photoSrc = photo && photo.fluid && photo.fluid.src;
			return (
				<Link key={id} to={`${ROUTES.newsroom.stories}/${slug || contentful_id}`} className={styles.story}>
					<div>
						<p>
							<FormattedDate value={new Date(createdAt)} {...FORMATTED_DATE_OPTIONS} locale={locale} />
						</p>
						<h3>{title}</h3>
						{photoSrc && <img src={photoSrc} alt={title} />}
					</div>
				</Link>
			);
		});
	};
	return (
		<ContentLayout>
			<div className={styles.container}>{renderStories()}</div>
		</ContentLayout>
	);
};
