import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';

import { useCloseOnOutsideClick } from '../../utils/hooks';
import styles from './BottomSlideModal.module.scss';

const BottomSlideModal = ({ title, content, isVisible, closeFn }) => {
	const el = useRef(null);
	useCloseOnOutsideClick(el, closeFn, undefined, [isVisible]);
	return (
		<>
			<div className={cx(styles.overlay, isVisible && styles.showOverlay)} />
			<div className={cx(styles.modal, isVisible && styles.showModal)} ref={el}>
				{title && <header>{title}</header>}
				<div className={styles.content}>{content}</div>
			</div>
		</>
	);
};

BottomSlideModal.propTypes = {
	title: PropTypes.string,
	content: PropTypes.node.isRequired,
	isVisible: PropTypes.bool.isRequired,
	closeFn: PropTypes.func.isRequired,
};

export default BottomSlideModal;
