import { useLocation } from '@reach/router';
import cx from 'classnames';
import { Link, useIntl } from 'gatsby-plugin-intl';
import PropTypes from 'prop-types';
import React from 'react';

import { HEADER_NAV_DATA, getQuickNavImages } from '../../utils';
import { ContentLayout } from '../';
import styles from './QuickBottomNavigation.module.scss';

export const QuickBottomNavigation = ({ dataArray, className }) => {
	const location = useLocation();
	const intl = useIntl();
	const quickNavImages = getQuickNavImages();
	const renderCard = (
		{ route, translationKey, descTranslationKey, buttonTranslationKey, hidden, isCustomLink },
		index
	) => {
		if (hidden) {
			return null;
		}
		const linkAttrs = {
			key: translationKey,
			className: styles.card,
			style: { backgroundImage: `url(${quickNavImages[index]})` },
		};
		const linkContent = (
			<>
				<h1>{intl.formatMessage({ id: `nav.${translationKey}` })}</h1>
				<p>{intl.formatMessage({ id: `nav.${descTranslationKey}` })}</p>
				<span>{intl.formatMessage({ id: buttonTranslationKey || 'read_more' })}</span>
			</>
		);
		if (isCustomLink) {
			return (
				<a href={route(intl.locale)} {...linkAttrs}>
					{linkContent}
				</a>
			);
		}
		return (
			<Link to={route} {...linkAttrs}>
				{linkContent}
			</Link>
		);
	};
	const renderCards = () => {
		if (dataArray) {
			return dataArray.map(renderCard);
		}
		const data = Object.values(HEADER_NAV_DATA);
		const navToShow = data.filter(({ route, isCustomLink }) => {
			if (isCustomLink) {
				return true;
			}
			const rgxp = new RegExp(`${route}`.split('/')[1], 'g');
			return !rgxp.test(location.pathname);
		});
		return navToShow.slice(0, 3).map(renderCard);
	};
	return <div className={cx(styles.wrapper, className)}>{renderCards()}</div>;
};

QuickBottomNavigation.propTypes = {
	dataArray: PropTypes.arrayOf(
		PropTypes.shape({
			route: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
			translationKey: PropTypes.string.isRequired,
			descTranslationKey: PropTypes.string.isRequired,
			buttonTranslationKey: PropTypes.string,
		})
	),
	className: PropTypes.string,
};

export const QuickBottomNavigationWrapper = ({ children, optionalDataArray }) => (
	<>
		{children}
		<section className={styles.container}>
			<ContentLayout>
				<QuickBottomNavigation dataArray={optionalDataArray} />
			</ContentLayout>
		</section>
	</>
);

QuickBottomNavigationWrapper.propTypes = {
	optionalDataArray: PropTypes.arrayOf(
		PropTypes.shape({
			route: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
			translationKey: PropTypes.string.isRequired,
			descTranslationKey: PropTypes.string.isRequired,
			buttonTranslationKey: PropTypes.string,
		})
	),
};
