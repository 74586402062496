import cx from 'classnames';
import { graphql, useStaticQuery } from 'gatsby';
import { Link, navigate, useIntl } from 'gatsby-plugin-intl';
import React from 'react';

import {
	QUICK_NAVIGATION_NEWSROOM,
	ROUTES,
	getLatestStories,
	getLocalizedDataFromContentfulEdges,
} from '../../../utils';
import { ContentLayout, QuickBottomNavigationWrapper, Slider } from '../../index';
import styles from './MobileMain.module.scss';

export const MobileMain = () => {
	const data = useStaticQuery(graphql`
		{
			allContentfulStory {
				edges {
					node {
						node_locale
						photo {
							fluid {
								src
							}
						}
						title
						description
						createdAt(formatString: "MMM D")
						id
						contentful_id
						slug
					}
				}
			}
		}
	`);
	const intl = useIntl();
	const stories = getLocalizedDataFromContentfulEdges(data.allContentfulStory.edges, intl.locale);
	const latestStories = getLatestStories(stories, 6);
	const renderStories = () => {
		if (!latestStories || latestStories.slice(3).length === 0) {
			return null;
		}
		return latestStories.slice(3).map(({ id, contentful_id, createdAt, title }) => (
			<Link key={id} to={`${ROUTES.newsroom.stories}/${contentful_id}`}>
				<div key={id} className={styles.mobileCard}>
					<div>{createdAt}</div>
					<h3>{title}</h3>
				</div>
			</Link>
		));
	};
	const renderCarousel = () => {
		if (!latestStories || latestStories.slice(0, 3).length < 1) {
			return null;
		}
		return latestStories.slice(0, 3).map(({ id, slug, contentful_id, photo, title }) => {
			const photoSrc = photo && photo.fluid && photo.fluid.src;
			return (
				<section key={id} className={styles.firstNews} style={{ backgroundImage: `url(${photoSrc})` }}>
					<Link to={`${ROUTES.newsroom.stories}/${slug || contentful_id}`}>
						<div className={cx(styles.mobileCard, styles.alignEnd)}>
							<h3>{title}</h3>
						</div>
					</Link>
				</section>
			);
		});
	};
	return (
		<div className={styles.mobileContainer}>
			<QuickBottomNavigationWrapper optionalDataArray={QUICK_NAVIGATION_NEWSROOM}>
				<Slider>{renderCarousel()}</Slider>
				<section className={styles.backgroundStories}>
					<ContentLayout>
						<div className={styles.storiesWrapper}>{renderStories()}</div>
						<button className={styles.seeMoreNewsButton} onClick={() => navigate(ROUTES.newsroom.pressReleases)}>
							{intl.formatMessage({ id: 'see_more' })}
						</button>
					</ContentLayout>
				</section>
			</QuickBottomNavigationWrapper>
		</div>
	);
};
