import { Link, useIntl } from 'gatsby-plugin-intl';
import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';

import { DESKTOP_HEADER_HEIGHT } from '../../utils';
import { ContentLayout } from '../ContentLayout';
import styles from './DesktopSubNav.module.scss';

export const DesktopSubNav = ({ activeNavItem }) => {
	const intl = useIntl();

	if (activeNavItem === undefined || activeNavItem.subRoutes === undefined) {
		return null;
	}

	const renderList = () => {
		return activeNavItem.subRoutes.map(({ route, translationKey, isCustomLink, isAnchor }) => {
			if (isAnchor) {
				return (
					<li key={translationKey}>
						<AnchorLink offset={DESKTOP_HEADER_HEIGHT} href={route}>
							{intl.formatMessage({ id: `nav.${translationKey}` })}
						</AnchorLink>
					</li>
				);
			}
			if (isCustomLink) {
				return (
					<li key={translationKey}>
						<a href={route} target="_blank" rel="noreferrer">
							{intl.formatMessage({ id: `nav.${translationKey}` })}
						</a>
					</li>
				);
			}
			return (
				<li key={translationKey}>
					<Link activeClassName={styles.active} to={route}>
						{intl.formatMessage({ id: `nav.${translationKey}` })}
					</Link>
				</li>
			);
		});
	};

	return (
		<div className={styles.container}>
			<ContentLayout>
				<header>{intl.formatMessage({ id: `nav.${activeNavItem.translationKey}` })}</header>
				<ul>{renderList()}</ul>
			</ContentLayout>
		</div>
	);
};
