import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import { useIntl } from 'gatsby-plugin-intl';
import React from 'react';

import { getLocalizedDataFromContentfulEdges } from '../../../utils';
import styles from './brands.module.scss';

export const Brands = () => {
	const data = useStaticQuery(graphql`
		{
			allContentfulBrands {
				edges {
					node {
						node_locale
						title
						brands {
							name
							logo {
								fluid(maxWidth: 240, quality: 100) {
									...GatsbyContentfulFluid
								}
							}
							slogan
							description {
								description
							}
						}
					}
				}
			}
		}
	`);
	const intl = useIntl();
	const {
		node: { title, brands },
	} = getLocalizedDataFromContentfulEdges(data.allContentfulBrands.edges, intl.locale)[0];

	const renderBrand = (brand, index) => {
		const { name, logo, slogan, description } = brand;
		const { fluid } = logo;
		return (
			<div key={`about-brands-${index}`} className={styles.brand}>
				<div className={styles.brandLogoWrapper}>
					<div className={styles.imgContainer}>{fluid && <Img fluid={fluid} alt={`${name} logo`} />}</div>
				</div>
				<h3 className={styles.brandSlogan}>{slogan}</h3>
				<p className={styles.brandDescription}>{description.description}</p>
			</div>
		);
	};
	return (
		<>
			<h2>{title}</h2>
			<div className={styles.brands}>{brands.map(renderBrand)}</div>
		</>
	);
};
