import cx from 'classnames';
import GatsbyImage from 'gatsby-image';
import React, { useState } from 'react';

import styles from './image.module.scss';
import ImagePreview from './ImagePreview';

const Image = ({ photo, previewPhoto, className, ...props }) => {
	const [isOpen, setIsOpen] = useState(false);
	const open = () => setIsOpen(true);
	const close = () => setIsOpen(false);
	if (!previewPhoto) {
		return <GatsbyImage {...props} {...photo} className={className} />;
	}
	return (
		<>
			<button type="button" onClick={open} className={cx([className, styles.imageWrapper])}>
				<GatsbyImage {...props} {...photo} onClick={open} />
				<div className={styles.imageWrapper__overlay} />
			</button>
			<ImagePreview photo={previewPhoto} visible={isOpen} close={close} />
		</>
	);
};

export default Image;
