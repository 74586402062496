import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './ContentLayout.module.scss';

export const ContentLayout = ({ children, className }) => {
	return <div className={cx(styles.container, className)}>{children}</div>;
};

ContentLayout.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
};
