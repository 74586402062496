import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';

class Portal extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			canRender: false,
		};
	}

	componentDidMount() {
		this.portalRoot = document.getElementById(this.props.id);
		if (!this.portalRoot) {
			const portalRoot = document.createElement('div');
			portalRoot.setAttribute('id', this.props.id);
			document.body.appendChild(portalRoot);
			this.portalRoot = portalRoot;
		}

		this.element = document.createElement('div');
		this.portalRoot.appendChild(this.element);
		this.setState({
			canRender: true,
		});
	}

	componentWillUnmount() {
		this.portalRoot.removeChild(this.element);
	}

	render() {
		const { children } = this.props;
		const { canRender } = this.state;
		if (!canRender) {
			return null;
		}
		return ReactDOM.createPortal(children, this.element);
	}
}

Portal.propTypes = {
	children: PropTypes.node,
};

Portal.defaultProps = {
	children: <></>,
};

export default Portal;
