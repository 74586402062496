import React, { PureComponent } from 'react';
import { CSSTransition } from 'react-transition-group';

import styles from './Animations.module.scss';

class Animations extends PureComponent {
	static Animation = ({ children, visible, duration = 200, animationName }) => {
		const transitionDuration = {
			transitionDuration: `${duration}ms`,
		};
		return (
			<CSSTransition
				in={visible}
				mountOnEnter
				unmountOnExit
				timeout={duration}
				style={transitionDuration}
				classNames={{
					enter: styles[`${animationName}Enter`],
					enterActive: styles[`${animationName}EnterActive`],
					exit: styles[`${animationName}Exit`],
					exitActive: styles[`${animationName}ExitActive`],
				}}>
				{children}
			</CSSTransition>
		);
	};

	static FadeInOut = props => <Animations.Animation {...props} animationName="animationFade" />;
}

export default Animations;
