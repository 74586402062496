import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import React, { useState } from 'react';

import { DESKTOP_HEADER_BREAKPOINT, DESKTOP_HEADER_OPTIONS_BREAKPOINT, getBrandTabPopoverData } from '../../utils';
import { DesktopHeader } from './DesktopHeader';
import { MobileHeader } from './MobileHeader';

export const Header = ({ countryLinks }) => {
	const [shouldHideOptions, setShouldHideOptions] = useState(false);

	// needed to hide header options on desktop sizes
	useScrollPosition(
		({ _, currPos }) => {
			if (window.innerWidth > DESKTOP_HEADER_BREAKPOINT) {
				if (currPos.y < DESKTOP_HEADER_OPTIONS_BREAKPOINT) {
					setShouldHideOptions(true);
				} else {
					setShouldHideOptions(false);
				}
			}
		},
		[],
		null,
		false,
		250
	);
	const popoverData = getBrandTabPopoverData(countryLinks);
	return (
		<header>
			<DesktopHeader shouldHideOptions={shouldHideOptions} popoverData={popoverData} />
			<MobileHeader />
		</header>
	);
};
