import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import CollapseIcon from '../../assets/icons/double-chevron.svg';
import styles from './collapse.module.scss';

export const Collapse = ({ contentClassName, content }) => {
	const [isOpen, setIsOpen] = useState(false);
	return (
		<div className={cx(contentClassName, styles.contentWrapper, isOpen && styles.isOpen)}>
			<div className={styles.collapseContent}>{content}</div>
			<button className={styles.collapseButton} onClick={() => setIsOpen(!isOpen)}>
				<CollapseIcon />
			</button>
		</div>
	);
};

Collapse.propTypes = {
	contentClassName: PropTypes.string,
	content: PropTypes.node.isRequired,
};
