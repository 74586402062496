import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Carousel } from 'react-responsive-carousel';

import ArrowIcon from '../../assets/icons/chevron-left-48-px.svg';
import styles from './slider.module.scss';

export const Slider = ({ children, ...props }) => {
	const { nextArrowClassName, prevArrowClassName, showThumbs = false, showStatus = false, infiniteLoop = true } = props;
	const nextArrowRenderer = onClick => (
		<div className={cx(styles.arrowButtonWrapper, styles.next, !!nextArrowClassName && nextArrowClassName)}>
			<button onClick={onClick} className={styles.arrowButton}>
				<ArrowIcon />
			</button>
		</div>
	);
	const prevArrowRenderer = onClick => (
		<div className={cx(styles.arrowButtonWrapper, styles.prev, !!prevArrowClassName && prevArrowClassName)}>
			<button onClick={onClick} className={styles.arrowButton}>
				<ArrowIcon />
			</button>
		</div>
	);
	return (
		<Carousel
			{...props}
			showThumbs={showThumbs}
			showStatus={showStatus}
			infiniteLoop={infiniteLoop}
			renderArrowNext={nextArrowRenderer}
			renderArrowPrev={prevArrowRenderer}>
			{children}
		</Carousel>
	);
};

Slider.propTypes = {
	props: PropTypes.shape({
		nextArrowClassName: PropTypes.string,
		prevArrowClassName: PropTypes.string,
		showThumbs: PropTypes.bool,
		showStatus: PropTypes.bool,
		infiniteLoop: PropTypes.bool,
	}),
	children: PropTypes.node.isRequired,
};
