import { graphql, useStaticQuery } from 'gatsby';
import { Link, navigate, useIntl } from 'gatsby-plugin-intl';
import React from 'react';

import {
	QUICK_NAVIGATION_NEWSROOM,
	ROUTES,
	getLatestReleases,
	getLatestStories,
	getLocalizedDataFromContentfulEdges,
} from '../../../utils';
import { ContentLayout, QuickBottomNavigation, Slider } from '../../index';
import styles from './DesktopMain.module.scss';

export const DesktopMain = () => {
	const data = useStaticQuery(graphql`
		{
			allContentfulPressRelease(sort: { fields: date, order: DESC }) {
				edges {
					node {
						id
						title
						date(formatString: "MMM D")
						pdf {
							file {
								url
							}
						}
						node_locale
					}
				}
			}

			allContentfulStory(sort: { fields: createdAt, order: DESC }) {
				edges {
					node {
						node_locale
						photo {
							fixed(width: 1400, quality: 100) {
								src
							}
						}
						title
						description
						createdAt(formatString: "MMM D")
						id
						contentful_id
						slug
					}
				}
			}
		}
	`);
	const intl = useIntl();
	const stories = getLocalizedDataFromContentfulEdges(data.allContentfulStory.edges, intl.locale);
	const pressReleases = getLocalizedDataFromContentfulEdges(data.allContentfulPressRelease.edges, intl.locale);
	const latestStories = getLatestStories(stories, 3);
	const latestReleases = getLatestReleases(pressReleases);
	const renderNewsList = () => {
		if (!latestReleases) {
			return null;
		}
		return latestReleases.map(({ title, date, id, pdf }) => {
			const pdfUrl = pdf && pdf.file && pdf.file.url;
			if (pdfUrl) {
				return (
					<div key={id} className={styles.newsItem}>
						<a href={`https:${pdfUrl}`}>
							<p>{title}</p>
							<p>{date}</p>
						</a>
					</div>
				);
			}
			return null;
		});
	};
	const renderCarouselStories = () => {
		if (!latestStories) {
			return null;
		}
		return latestStories.map(({ id, contentful_id, slug, photo, title, description }) => {
			const photoSrc = photo && photo.fixed && photo.fixed.src;
			return (
				<section
					key={id}
					className={styles.carouselItem}
					style={{ background: `no-repeat center / cover url(${photoSrc})` }}>
					<div className={styles.card}>
						<h2>{title}</h2>
						<p>{description}</p>
						<div className={styles.linkWrapper}>
							<Link to={`${ROUTES.newsroom.stories}/${slug || contentful_id}`}>
								{intl.formatMessage({ id: 'read_more' })}
							</Link>
						</div>
					</div>
				</section>
			);
		});
	};
	return (
		<div className={styles.container}>
			<ContentLayout>
				<Slider>{renderCarouselStories()}</Slider>
				<section className={styles.mainSection}>
					<div className={styles.news}>
						<h1>{intl.formatMessage({ id: 'latest_news' })}</h1>
						{renderNewsList()}
						<button onClick={() => navigate(ROUTES.newsroom.pressReleases)} className={styles.seeMoreNewsButton}>
							{intl.formatMessage({ id: 'see_more' })}
						</button>
					</div>
					<QuickBottomNavigation dataArray={QUICK_NAVIGATION_NEWSROOM} className={styles.blocks} />
				</section>
			</ContentLayout>
		</div>
	);
};
