import { useIntl } from 'gatsby-plugin-intl';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import BottomSlideModal from '../../ui/BottomSlideModal/BottomSlideModal';
import { countryLinkPropTypes, mapFlagInformationToBrands } from '../../utils';
import { FlagBrands } from '../';
import Popover from './Popover';
import styles from './Popover.module.scss';

const CustomerServicePopover = ({ children, data }) => {
	const intl = useIntl();
	const [isMobileModalOpen, setIsMobileModalOpen] = useState(false);
	const content = <FlagBrands flagData={mapFlagInformationToBrands(data)} />;

	const handleMobileModalToggle = event => {
		event.stopPropagation();
		setIsMobileModalOpen(!isMobileModalOpen);
	};

	return (
		<>
			<div className={styles.modalContainer}>
				<BottomSlideModal
					title={intl.formatMessage({ id: 'modal.select_location' })}
					content={<div className={styles.customerServiceModalBody}>{content}</div>}
					isVisible={isMobileModalOpen}
					closeFn={() => setIsMobileModalOpen(false)}
				/>
				<button onClick={handleMobileModalToggle}>{children}</button>
			</div>
			<div className={styles.popoverContainer}>
				<Popover children={children} content={content} className={styles.customerServicePopover} position={'top'} />
			</div>
		</>
	);
};

Popover.propTypes = {
	children: PropTypes.node.isRequired,
	data: PropTypes.arrayOf(countryLinkPropTypes),
};

export default CustomerServicePopover;
