import cx from 'classnames';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import ReactTinyPopover from 'react-tiny-popover';

import styles from './Popover.module.scss';

const Popover = ({ children, content, className, position }) => {
	const [isPopoverOpen, setIsPopoverOpen] = useState(false);
	const debouncedFunction = debounce(
		() => {
			if (isPopoverOpen) {
				setIsPopoverOpen(false);
			}
		},
		100,
		{ leading: true }
	);
	useEffect(() => {
		if (isPopoverOpen) {
			window.addEventListener('scroll', debouncedFunction);
		}
		return () => window.removeEventListener('scroll', debouncedFunction);
	}, [isPopoverOpen, debouncedFunction]);
	return (
		<ReactTinyPopover
			isOpen={isPopoverOpen}
			onClickOutside={() => setIsPopoverOpen(false)}
			containerClassName={cx(styles.popover, className)}
			position={position}
			content={isPopoverOpen ? content : null}>
			<button onClick={() => setIsPopoverOpen(!isPopoverOpen)}>{children}</button>
		</ReactTinyPopover>
	);
};

Popover.propTypes = {
	children: PropTypes.node.isRequired,
	content: PropTypes.node.isRequired,
	className: PropTypes.string,
	position: PropTypes.string,
};

Popover.defaultProps = {
	position: 'top',
};

export default Popover;
