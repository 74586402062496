import { graphql, useStaticQuery } from 'gatsby';
import { useIntl } from 'gatsby-plugin-intl';
import React from 'react';

import { getLocalizedDataFromContentfulEdges, groupCountriesByYear } from '../../../utils';
import Tooltip from '../../Tooltip/Tooltip';
import styles from './development.module.scss';
import { DevelopmentCarousel } from './DevelopmentCarousel';

export const Development = () => {
	const intl = useIntl();
	const data = useStaticQuery(graphql`
		{
			allContentfulDevelopment {
				edges {
					node {
						title
						node_locale
						countries {
							name
							code
							year
							flag {
								fluid {
									src
								}
							}
						}
					}
				}
			}
		}
	`);
	const {
		node: { title, countries },
	} = getLocalizedDataFromContentfulEdges(data.allContentfulDevelopment.edges, intl.locale)[0];
	const yearList = groupCountriesByYear(countries);

	const renderCountry = (country, index) => {
		const { name, code, year, flagSrc } = country;
		return (
			<div key={`about-development-${year}-${index}`} className={styles.country}>
				<Tooltip text={code} place="top">
					<img src={flagSrc} alt={`${name} flag`} />
				</Tooltip>
			</div>
		);
	};

	const renderTwoFlagRows = () => {
		const firstRow = yearList.slice(0, 5);
		const secondRow = yearList.slice(5);

		return (
			<>
				<div className={styles.developmentDesktop}>{firstRow.map(renderYear)}</div>
				<div className={styles.developmentDesktop}>{secondRow.map(renderYear)}</div>
			</>
		);
	};

	const renderYear = (yearData, index) => {
		const year = yearData[0].year;
		return (
			<div key={`about-development-${year}`} className={styles.year}>
				<div className={styles.countries}>{yearData.map(renderCountry)}</div>
				<span>{year}</span>
			</div>
		);
	};

	return (
		<>
			<h2>{title}</h2>
			<div className={styles.developmentDesktopContainer}>{renderTwoFlagRows()}</div>
			<div className={styles.developmentMobile}>
				<DevelopmentCarousel data={yearList} />
			</div>
		</>
	);
};
